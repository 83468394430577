/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyClF4-CbI9auFr76IPnmy-HRJxJbAzz3qs",
  "appId": "1:26447342407:web:69e7c6ff73c4015183fa62",
  "authDomain": "schooldog-i-escambia-al.firebaseapp.com",
  "measurementId": "G-HTZHXJZ1MN",
  "messagingSenderId": "26447342407",
  "project": "schooldog-i-escambia-al",
  "projectId": "schooldog-i-escambia-al",
  "showGaBranding": false,
  "storageBucket": "schooldog-i-escambia-al.appspot.com"
}
